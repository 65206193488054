// MapComponent.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import AudioPlayer from './AudioPlayer';
import L from 'leaflet';

const customIcon = new L.Icon({
  iconUrl: 'like.png',
  iconSize: [30, 30], // Icon size in pixels
  iconAnchor: [16, 30], // Anchor point relative to the icon's top-left corner
});

const CustomPopup = ({ location }) => {
  return (
    <div>
      <h2>{location.name}</h2>
      <p>{location.description}</p>
      <AudioPlayer mp3name={location.mp3name} songtitle={location.songtitle} />
    </div>
  );
};

const MapComponent = ({ locations }) => {
  const center = [51.07166683559667, 3.8001546057375926]; // Set your initial map center here

  return (
    <MapContainer center={center} zoom={5} style={{ height: '100%', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {locations.map((location, index) => (
        <Marker key={index} position={location.coords} icon={customIcon}>
          <Popup>
            <CustomPopup location={location} />
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default MapComponent;