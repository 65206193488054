import './App.css';

import React, { useState } from 'react';
import MapComponent from './MapComponent';




function App() {

  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);
  const handleButtonClick = () => {
    setShowDiv1(!showDiv1);
    setShowDiv2(!showDiv2);
  };

  const locations = [
    { coords: [40.7128, -74.0060], 
      name: 'New York' , 
      description: 'Hier komen we zoenen.',
      mp3name: 'dtardennen.mp3',
      songtitle : "Ardennen",
     },
    { coords: [48.8859775124437, 2.343185002564073], 
      name: 'Paris' , 
      description: 'Ruzie is ook liefde!',
      mp3name: 'dtfantasia.mp3',
      songtitle : "fantasia",
    },
    { coords: [51.07166683559667, 3.8001546057375926], 
      name: 'Te Winkel' , 
      description: 'Ons eerste officieel document.',
      mp3name: 'achter1.mp3',
      songtitle : "achter 1",
    },
    { coords: [  49.56570401170071, 3.60148502081763    ], 
      name: 'Laon' , 
      description: 'Knie doet het nie.',
      mp3name: 'uwbegrafenis.mp3',
      songtitle : "uw begrafenis",
   },
    { coords: [  -43.982615107371025, 170.19539928050534    ], 
      name: 'New Zealand' , 
      description: 'Hier staan we ooit!',
      mp3name: 'liggeninhetgras.mp3',
      songtitle : "liggen in het gras",
    },
    { coords: [  36.15487141614435, -115.15159273050031    ], 
      name: 'Las Vegas' , 
      description: 'Hier trouwen we!',
      mp3name: 'mimiredola.mp3',
      songtitle : "niniredola",
    },
    { coords: [  50.43853621805779, 14.63418693308388    ], 
      name: 'Mšeno' , 
      description: 'Liefde in een tent.',
      mp3name: 'minstens14keer.mp3',
      songtitle : "minstens 14 keer",
    },
    { coords: [  50.95086924942952, 3.1033035206627915    ], 
      name: 'Roeselare' , 
      description: 'Hier is alles geboren.',
      mp3name: 'opdeheuvel.mp3',
      songtitle : "op de heuvel",
    },
    { coords: [  50.070959679908896, 4.259202390229991    ], 
      name: 'Chimay' , 
      description: 'Een droge tent is heel wat.',
      mp3name: 'she walks into the coffee shop.mp3',
      songtitle : "she walks into the coffee shop",
    },
    { coords: [  52.5133017311516, 6.319743334497172    ], 
      name: 'Natuur huisje' , 
      description: 'Rust was nodig.',
      mp3name: 'she walks out of the coffee shop.mp3',
      songtitle : "she walks out of the coffee shop",
    },
    { coords: [  50.909803121618125, 2.9470563539983154   ], 
      name: 'Tiny Kot' , 
      description: 'Warm Water Ton',
      mp3name: 'SlowmotionRain.mp3',
      songtitle : "slow motion regen",
    },
    { coords: [  51.031449729977965, 4.480264865354471   ], 
      name: 'Mechelen' , 
      description: 'CIRKEL VAN LIEFDE',
      mp3name: 'krabbenondermaanlicht.mp3',
      songtitle : "Krabben onder maanlicht",
    },
  ];
  

  return (
    <div className="App">
          {showDiv1 && <div>
            <div className="cursor heart-pulsate" onClick={handleButtonClick}>ILYSFM</div>
          </div>}
          {showDiv2 && <div className="Map-Div rounded-map-container">
            <MapComponent locations={locations} />
          </div>}
    </div>

  );
}

export default App;
