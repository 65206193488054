import React, { useState, useRef } from 'react';

const styles = {
  image: {
    width: '30px',
    height: '30px',
  },
};


const AudioPlayer = ( props ) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };


  const mp3fullsource = "/" + props.mp3name

  return (
    <div>
      <audio ref={audioRef} src={mp3fullsource} />
      <p>{props.songtitle}</p>
      <button onClick={handlePlay}>{isPlaying ? (<img src='./pause-button.png' alt="Your Image" style={styles.image} />) : (<img src='./play.png' alt="Your Image" style={styles.image} />)}</button>

      
    </div>
  );
};

export default AudioPlayer